import React, { useState, useEffect, useRef  } from 'react';
import { useParams } from 'react-router-dom';
import {
  TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, Typography,
  IconButton, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Box, MenuItem, Autocomplete
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert';
import Alert from '@mui/material/Alert';
import verificareToken from './VerificareToken';
import DrepturiUtilizator from './utilizator/drepturiUtilizator'; 
import { DataGridPro, GridFilterInputValue, useGridApiRef, useGridApiContext, GridToolbarContainer, GridToolbarExportContainer, GridPrintExportMenuItem, GridCsvExportMenuItem, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, roRO, getGridStringOperators,   GridColDef,   GridFilterOperator, GridPrintGetRowsToExportParams, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector, GridRowId,   } from '@mui/x-data-grid-pro'; 

import { format, parseISO, parse, isPast, endOfDay  } from 'date-fns';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/system';
import HelpDialog from './HelpDialog';
import { ExportInExcel } from './ExportInExcel';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ro } from 'date-fns/locale';

import { callAddFont } from './fonts/DejaVuSans-normal';
function formateazaData(dataString) {
    const data = new Date(dataString);
    const zi = data.getDate().toString().padStart(2, '0');
    const luna = (data.getMonth() + 1).toString().padStart(2, '0');  
    const an = data.getFullYear();
    return `${zi}.${luna}.${an}`;
  }

 
  function formateazaTimp(timp) {
    const ore = Math.floor(timp);  
    const minute = Math.round((timp - ore) * 100);  
  
    if (ore === 0 && minute > 0) {
      return `${minute} minute`;
    } else if (ore > 0 && minute === 0) {
      return `${ore} ore`;
    } else if (ore > 0 && minute > 0) {
      return `${ore} ore și ${minute} minute`;
    } else {
      return '';
    }
  }

  
function Activitati() {
    const { areDrept } = DrepturiUtilizator(); 
    const AdaugareUtilizatoriDrept = 'AdaugareUtilizatori'; 
    const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
  const { token } = verificareToken();
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    data: true,
    text: true,
    timp: true,
    tipactivitate: true, 
    denumireUser: true, 
    numarDosar: true,
  });
    // Calculează datele pentru începutul și sfârșitul lunii curente
    const initialStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0];
    const initialEndDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().split('T')[0];
  
    // Inițializează state-ul cu valorile calculate
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    
const formattedStartDate2 = startDate ? format(parseISO(startDate), 'dd.MM.yyyy') : '';
const formattedEndDate2 = endDate ? format(parseISO(endDate), 'dd.MM.yyyy') : '';

const [initialUserSet, setInitialUserSet] = useState(false); 

  const [dateFieldName, setDateFieldName] = useState('any');
  const FlexContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  });

const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('ro-RO').replace(/\//g, '-');
const formattedTime = currentDate.toLocaleTimeString('ro-RO', { hour12: false }).replace(/:/g, '-');
const formattedTime2 = currentDate.toLocaleTimeString('ro-RO', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false  
});
  const [showHeader, setShowHeader] = useState(false);
  const [showConfidential, setShowConfidential] = useState(false); 
  const [usercurentgrup, setUsercurentgrup] = useState([]);
  const [usercurentnume, setUsercurentnume] = useState([]);
  function getKeysFromLookup(obj: GridFilterState['filteredRowsLookup']) {
    const keys = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        keys.push(key);
      }
    }
    return keys;
  }

  const fileNameExcel = `JuristPRO NEXT - Export EXCEL - ${formattedDate} - ${formattedTime}`;
  const fileNameCSV = `JuristPro NEXT - CSV Export lista dosare - ${formattedDate} - ${formattedTime}`;
  const formatUserGroups = (userGroups) => {
      if (!userGroups || userGroups.length === 0) return 'Fără grup';
      return userGroups.map(group => group.name).join(', ');
    };
   
  
  function getDenAppVers() {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null; 
    }
  
    let denAppVers = getCookie('DenAppVers');
  
    if (!denAppVers) {
      denAppVers = localStorage.getItem('DenAppVers');
    }
  
    if (!denAppVers) {
      denAppVers = sessionStorage.getItem('DenAppVers');
    }
  
    return denAppVers || 'V2';  
  }
  
  let denAppVers = getDenAppVers(); 
const [dosareFiltrate, setDosareFiltrate] = React.useState([]);
const [sortModel, setSortModel] = useState([]);
 

const [users, setUsers] = useState([]);  

const [selectedUser, setSelectedUser] = useState(null);
 
  const [isLoading, setLoading] = useState(false); 
  const [dosarSelectat, setDosarSelectat] = useState(null); 
  const [activitati, setActivitati] = useState([]);
  const [activitateUser, setActivitateUser] = useState(false);
  
  const [editareId, setEditareId] = useState(null);

  const [dosare, setDosare] = useState([]);  

  const [dateFormular, setDateFormular] = useState({
      data: new Date(),
      text: '',
      timp: '',
      minute: ''
  });
  const formateazaDataPentruBackend = (data) => {
    const offset = data.getTimezoneOffset() * 60000;  
    const dataLocala = new Date(data.getTime() - offset);
    return dataLocala.toISOString().split('T')[0];
  };
  
  const [modalDeschis, setModalDeschis] = useState(false);
  const [incarca, setIncarca] = useState(false);

  
const fetchDosare = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}dosare?arhivat=0`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,  
        },
      });

      if (response.ok) {
        const data = await response.json();
        setDosare(data.data.dosare.map((dosar) => ({ id: dosar.id, nume: dosar.numardosarfinal })));
      } else {
        throw new Error('Eroare la obținerea listei de dosare.');
      }
    } catch (error) {
      console.error('Eroare la obținerea listei de dosare: ', error);
    }
  };

  useEffect(() => {
    const fetchUsercurent = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        
        const {data} = await response.json();
        setUsercurentgrup(data.user.groupNames);
        setUsercurentnume(data.user.fullname);
      } catch (error) {
        console.error("Eroare la preluarea utilizatorilor", error);
      }
    };
  
    fetchUsercurent();
  }, [token]);
  
  
  useEffect(() => {
    const incarcaDate = async () => {
      setIncarca(true);
      setLoading(true);
      await fetchDosare();  // Așteaptă să se încarce dosarele
      fetch(`${process.env.REACT_APP_API_URL}activitati`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          setActivitati(data.data.activitatiDosar);
          
          setDosareFiltrate(data.data.activitatiDosar);
          // Găsim cea mai mică dată din activități
            const activitati = data.data.activitatiDosar;
            if (activitati.length > 0) {
                // Convertim toate datele din string în obiecte Date
                const dateActivitati = activitati.map((activitate) => new Date(activitate.data));
                
                // Găsim data cea mai mică
                const dataMinima = new Date(Math.min(...dateActivitati));

                // Setăm startDate la această dată formatată
                setStartDate(dataMinima.toISOString().split('T')[0]); // sau formatează cum dorești
            }
          setIncarca(false);
          setLoading(false);
        })
        .catch(eroare => {
          console.error('A apărut o eroare!', eroare);
          setIncarca(false);
          setLoading(false);
        });
    };
    
    incarcaDate();
  }, []);
  

  //calcul total ore si minute
  const [oreTotale, setOreTotale] = useState(0);
  const [minuteTotale, setMinuteTotale] = useState(0);
  
  useEffect(() => {
    let totalOre = 0;
    let totalMinute = 0;

    activitati.forEach(activitate => {
        const ore = Math.floor(activitate.timp);
        const minute = Math.round((activitate.timp - ore) * 100); 
        
        totalOre += ore;
        totalMinute += minute;
    });

    totalOre += Math.floor(totalMinute / 60);
    totalMinute = totalMinute % 60;

    setOreTotale(totalOre);
    setMinuteTotale(totalMinute);
}, [activitati]);

  
 //final calcul

 const formatMinute = (minute) => {
  return ('0' + minute).slice(-2);  
}

 // Funcție pentru încărcarea activităților în funcție de utilizator
 const fetchActivitati = (userId) => {
    setIncarca(true);
    fetch(`${process.env.REACT_APP_API_URL}activitati/?idUser=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDosareFiltrate(data.data.activitatiDosar);
        const activitati = data.data.activitatiDosar;
        if (activitati.length > 0) {
          const dateActivitati = activitati.map((activitate) => new Date(activitate.data));
          const dataMinima = new Date(Math.min(...dateActivitati));
          setStartDate(dataMinima.toISOString().split('T')[0]);
        }
        setIncarca(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error('A apărut o eroare!', error);
        setIncarca(false);
        setLoading(false);
        Swal('Atenție!', 'A apărut o eroare!', 'warning');
      });
  };
const handleMinuteChange = (e) => {
  const valoareIntrodusa = parseInt(e.target.value, 10);

  if (valoareIntrodusa < 0 || valoareIntrodusa > 59) {
    Swal('Atenție!', 'Minutele trebuie să fie între 0 și 59!', 'warning');
    return;
  }

  const minuteCorecte = (valoareIntrodusa / 100).toFixed(2);

  setDateFormular((prev) => ({ ...prev, minute: minuteCorecte }));
};

  const handleAdauga = () => {
    setIncarca(true);
  
    const ore = (dateFormular.timp || 0);
    const minute = formatMinute(dateFormular.minute || 0);  
  
    //console.log('ore', ore);
    //console.log('min', minute);
    const timpFinal = (parseFloat(ore) + parseFloat(minute) / 100).toFixed(2);  
  
    //console.log('timpFinal', timpFinal);
 
    const dataFormata = formateazaDataPentruBackend(dateFormular.data);
   
    const url = `${process.env.REACT_APP_API_URL}activitati?data=${dataFormata}&text=${dateFormular.text}&timp=${timpFinal}` + 
    (dosarSelectat ? `&iddosar=${dosarSelectat.id}` : '');

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => response.json())  
    .then(responseData => {
        setActivitati(prevActivitati => [...prevActivitati, responseData.data.activitateDosar]);
        setDateFormular({
            data: '',
            text: '',
            timp: ''
        });
        setModalDeschis(false);
        setIncarca(false);
        Swal('Succes!', 'Activitate adăugată cu succes!', 'success');
    })
    .catch(eroare => {
        setIncarca(false);
        Swal('Atenție!', 'A apărut o eroare!', 'warning');
        console.error('A apărut o eroare!', eroare);
    });
}


const editeazaActivitate = (id) => {
  setIncarca(true);

 

  const ore = (dateFormular.timp || 0);
  const minute = formatMinute(dateFormular.minute || 0);  

  //console.log('oreED', ore);
  //console.log('minED', minute);
  const timpFinal = (parseFloat(ore) + parseFloat(minute) / 100).toFixed(2);  

  const dataFormata = dateFormular.data instanceof Date ? formateazaDataPentruBackend(dateFormular.data) : dateFormular.data;
  //console.log('timpFinalED', timpFinal); 
  fetch(`${process.env.REACT_APP_API_URL}activitati/${id}?text=${dateFormular.text}&data=${dataFormata}&timp=${timpFinal}&iddosar=${dosarSelectat.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json())
    .then(responseData => {
      const activitateActualizata = responseData.data.activitateDosar;
      const activitatiActualizate = activitati.map(item => item.id === id ? activitateActualizata : item);
      setActivitati(activitatiActualizate);
      Swal('Succes!', 'Activitate actualizată cu succes!', 'success');
      setModalDeschis(false);
      setIncarca(false);
    })
    .catch(eroare => {
      Swal('Atenție!', 'A apărut o eroare!', 'warning');
      console.error('A apărut o eroare!', eroare);
      setIncarca(false);
    });
};


    const stergeActivitate = (id) => {
        Swal({
            title: 'Sunteți sigur?',
            text: 'Odată ștearsă, nu se mai poate recupera această activitate!',
            icon: 'warning',
            buttons: ['Anulează', 'Șterge'],
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                fetch(`${process.env.REACT_APP_API_URL}activitati/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                })
                .then(() => {
                    const userIdToLoad = selectedUser ? selectedUser.id : getCurrentUserId();
                    fetchActivitati(userIdToLoad);
                    
                    Swal('Ștearsă!', 'Activitatea a fost ștearsă.', 'success');
                })
                .catch(eroare => {
                    Swal('Atenție!', 'A apărut o eroare!', 'warning');
                    console.error('A apărut o eroare!', eroare);
                });
            } else {
                Swal('Activitatea nu a fost ștearsă!');
            }
        });
    }
  
    const deschideModalAdaugare = () => {
        setActivitateUser(true);
        setDosarSelectat(null);
        setDateFormular({
          data: new Date(),
          text: '',
          timp: ''
        });
        setEditareId(null);
        setModalDeschis(true);
      };

      const deschideModalAdaugareDosar = () => {
        setActivitateUser(false);
        setDosarSelectat(null);
        setDateFormular({
          data: new Date(),
          text: '',
          iddosar: '',
          timp: ''
        });
        setEditareId(null);
        setModalDeschis(true);
      };
    
      const deschideModalEditare = (activitate) => {
        if (dosare.length === 0) {
         // console.error('Lista de dosare nu este încărcată.');
         // return;   
        }
        
        const ore = Math.floor(activitate.timp);
        const minute = Math.round((activitate.timp - ore) * 100);
        const dataActivitate = new Date(activitate.data);
        const dosar = dosare.find(d => d.id === activitate.iddosar);
    
        console.log('Dosar selectat:', dosar);
        setActivitateUser(false);
        if (!dosar) {
         // console.error(`Dosarul cu id ${activitate.iddosar} nu a fost găsit.`);
          setActivitateUser(true);
        }
    
        setDateFormular({
          data: dataActivitate,
          text: activitate.text,
          timp: ore,
          minute: minute,
        });
    
        setDosarSelectat(dosar);
        setEditareId(activitate.id);
        setModalDeschis(true);
    };
    
      
    
      const handleSalvare = () => {
        if (editareId) {
          editeazaActivitate(editareId);
        } else {
          handleAdauga();
        }
      };

 
      const isFormularValid = dateFormular.data && 
      (typeof dateFormular.text === 'string' && dateFormular.text.trim() !== '') &&
      (dateFormular.timp !== undefined && dateFormular.timp.toString().trim() !== '') &&
      (dateFormular.minute !== undefined && dateFormular.minute.toString().trim() !== '');
    

        const timpValid = Number(dateFormular.timp) !== NaN && isFinite(Number(dateFormular.timp));
        const minuteValid = Number(dateFormular.minute) !== NaN && isFinite(Number(dateFormular.minute)) && parseInt(dateFormular.minute, 10) >= 0 && parseInt(dateFormular.minute, 10) < 60;

        const isFormularCompletValid = isFormularValid && timpValid && minuteValid;

        const oreOptions = Array.from({ length: 24 }, (_, i) => (
        <MenuItem key={i} value={i}>
            {i}
        </MenuItem>
        ));
        const minuteOptions = Array.from({ length: 60 }, (_, i) => (
        <MenuItem key={i} value={i}>
            {i < 10 ? `0${i}` : i}
        </MenuItem>
        ));

        const stringFilterOperators = getGridStringOperators();
        const removeDiacritics = (str: string) => {
          if (!str) {
            return str;
          }
          return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        };
        const wrapFilterOperator = (operator: GridFilterOperator) => {
            const getApplyFilterFn: GridFilterOperator["getApplyFilterFn"] = (
              filterItem,
              column
            ) => {
              const innerFilterFn = operator.getApplyFilterFn(
                {
                  ...filterItem,
                  value: removeDiacritics(filterItem.value)
                },
                column
              );
              if (!innerFilterFn) {
                return innerFilterFn;
              }
          
              return (params) => {
                const newParams = {
                  ...params,
                  value: removeDiacritics(params.value)
                };
                return innerFilterFn(newParams);
              };
            };
          
            return {
              ...operator,
              getApplyFilterFn
                };
            };

        const columns = [
            { field: 'denumireUser', headerName: 'Utilizator', flex: 1, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
            { field: 'text', headerName: 'Descriere Activitate', flex: 1 },
            { field: 'data', headerName: 'Dată Activitate', flex: 1, valueGetter: (params) => formateazaData(params.row.data) },
            { field: 'timp', headerName: 'Timp alocat', flex: 1, valueGetter: (params) => formateazaTimp(params.row.timp) },
            { field: 'tipactivitate', headerName: 'Tip Activitate', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
            { field: 'numarDosar', headerName: 'Nr. dosar', flex: 1, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
            {
              field: 'actions',
              headerName: 'Acțiuni',
              flex: 1,
              renderCell: (params) => (
                <>
                  {areDrept(DreptDeModificareDosare) && (
                    <>
                      <IconButton edge="end" aria-label="edit" onClick={() => deschideModalEditare(params.row)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={() => stergeActivitate(params.row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </>
              ),
            },
          ];

        const rows = activitati.map(activitate => ({
            id: activitate.id,
            text: activitate.text,
            denumireUser: activitate.denumireUser,
            data: activitate.data,
            timp: activitate.timp,
            is_activitate_dosar: activitate.is_activitate_dosar,
        }));

        
    
        const prepareDataForExport2 = (data, columns, columnVisibilityModel) => {
            return data.map((row) => {
              const formattedRow = {};
              columns.forEach((col) => {
                // Exclude coloana 'actions' și verifică dacă este vizibilă
                if (col.field !== 'actions' && columnVisibilityModel[col.field]) {
                  const displayName = col.headerName; // Denumirea din columns
                  
                  // Aplicăm formatarea pentru data activității
                  if (col.field === 'data') {
                    formattedRow[displayName] = formateazaData(row[col.field]);
                  } else {
                    formattedRow[displayName] = row[col.field]; // Adaugă datele corespunzătoare
                  }
                }
              });
              return formattedRow;
            });
        };
        
  //functii
  const [filterModel, setFilterModel] = useState({
    items: [], 
  });
  const handleFilterModelChange = (newModel) => {
 
    const newItems = newModel.items.map((item) => {
 
      return item;
    });
  
    setFilterModel({ ...newModel, items: newItems });
  };
  
  

  const selectedRows2 = dosareFiltrate;
  const dosareFinal = dosareFiltrate;
   
  const apiRef2 = useGridApiRef();
  const rowsToDisplay = dosareFiltrate;


  const handleStateChange = React.useCallback(
    (state: GridState) => {
      const filteredKeys = getKeysFromLookup(state.filter.filteredRowsLookup);
      const filteredRows = filteredKeys.map((key) => apiRef2.current.getRow(key));
  
      // Sortăm rândurile filtrate în funcție de sortModel-ul curent
      const sortedFilteredRows = sortRows(filteredRows, sortModel); // sortModel trebuie să fie disponibil global sau transmis aici
  
      // Verificăm dacă rândurile s-au schimbat față de cele deja filtrate
      if (JSON.stringify(sortedFilteredRows) !== JSON.stringify(dosareFiltrate)) {
        setDosareFiltrate(sortedFilteredRows);
      }
    },
    [apiRef2, dosareFiltrate, sortModel],  // sortModel trebuie inclus în dependențe
  );
  
  
const sortNumarDosar = (a, b) => { 
  const numarA = parseInt(a.split('/')[0]);
  const numarB = parseInt(b.split('/')[0]);

  if (numarA < numarB) return -1;
  if (numarA > numarB) return 1;
  return 0;
};
const compareValues = (a, b, field, sortDirection) => {
  // Verificăm dacă este `numardosarfinal`, folosim funcția specializată
  if (field === 'numarDosar') {
    const result = sortNumarDosar(a[field], b[field]);
    return sortDirection === 'asc' ? result : -result;
  }

  // Pentru câmpurile care au valori `undefined` sau `null`, le punem la început
  if (a[field] === undefined || a[field] === null) return sortDirection === 'asc' ? -1 : 1;
  if (b[field] === undefined || b[field] === null) return sortDirection === 'asc' ? 1 : -1;

  // Comparare pentru string-uri
  if (typeof a[field] === 'string') {
    return sortDirection === 'asc'
      ? a[field].localeCompare(b[field])
      : b[field].localeCompare(a[field]);
  }

  // Comparare pentru numere sau date
  if (typeof a[field] === 'number' || a[field] instanceof Date) {
    return sortDirection === 'asc'
      ? a[field] - b[field]
      : b[field] - a[field];
  }

  return 0;
};

// Funcția pentru sortarea rândurilor pe baza modelului de sortare
const sortRows = (rows, sortModel) => {
if (!sortModel.length) return rows; // Dacă nu există niciun criteriu de sortare, returnează rândurile inițiale

// Extrage primul criteriu de sortare (coloană și direcție)
const { field, sort } = sortModel[0];

// Sortăm rândurile utilizând `compareValues`
return [...rows].sort((a, b) => compareValues(a, b, field, sort));
};

const handleSortModelChange = (sortModel) => {
  // Sortăm rândurile pe baza modelului de sortare
  const sortedRows = sortRows(rowsToDisplay, sortModel);

  // Actualizăm datele filtrate (acum sortate)
  setDosareFiltrate(sortedRows);

  // Stocăm modelul de sortare pentru a-l folosi în alte funcții
  setSortModel(sortModel);  // Trebuie să păstrezi modelul de sortare
};

const handleExportXML = () => { 
    const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false && col.field !== 'actions');

    if (visibleColumns.length === 0 || dosareFinal.length === 0) {
      console.error('Nu există date disponibile pentru a genera XML-ul.');
      return;
    }
  
    // Construim structura XML
    let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>\n';
    xmlContent += '<Dosare>\n';
  
    dosareFinal.forEach(row => {
      xmlContent += '  <Dosar>\n';
      visibleColumns.forEach(col => {
        const fieldName = col.headerName.replace(/\s+/g, '_'); // înlocuim spațiile din denumirea coloanei cu '_'
        let cellValue;
  
        // Formatarea specială pentru user_groups
        if (col.field === 'user_groups') {
          cellValue = formatUserGroups(row[col.field]); // Folosește funcția de formatare
        } else {
          cellValue = row[col.field] || '';
        }
  
        xmlContent += `    <${fieldName}>${cellValue}</${fieldName}>\n`;
      });
      xmlContent += '  </Dosar>\n';
    });
  
    xmlContent += '</Dosare>';
  
    // Creăm fișierul XML și oferim descărcarea
    const blob = new Blob([xmlContent], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'export_dosare.xml';
    link.click();
  };
  
const dateFilterType = dateFieldName;
const prepareDataForExport = (data, columns, columnVisibilityModel) => {
    const columnInfoMap = columns.reduce((acc, column) => {
      if (columnVisibilityModel[column.field] !== false) {  
        acc[column.field] = column.headerName || column.field;
      }
      return acc;
    }, {});
  
    const orderedColumnFields = columns
      .filter(col => columnVisibilityModel[col.field] !== false)
      .map(col => col.field);
  
    return data.map(row => {
      const filteredRow = {};
      orderedColumnFields.forEach(key => {
        if (key !== '__check__') {
          filteredRow[columnInfoMap[key]] = row[key];
        }
      });
      return filteredRow;
    });
  };
const dataGridRef = useRef(null);

const handleExportPDFComand = () => {

  setShowHeader(false);
  setShowConfidential(false);
  let text;

  text = `${selectedRows2.length} de rânduri vor fi exportate în PDF din tabel`;

  // Creăm un container HTML pentru switch-uri
  const container = document.createElement('div');
  container.className = 'divswal'; // Adăugăm o clasă pentru stilizare

  // Inserăm HTML pentru switch-uri personalizate stilizate
  container.insertAdjacentHTML(
    'beforeend',
    `
    <div class="switch-container">
      <label for="headerSwitch">Afișare antet</label>
      <label class="switch">
        <input type="checkbox" id="headerSwitch" ${showHeader ? 'checked' : ''}>
        <span class="slider round"></span>
      </label>
    </div>
    <div class="switch-container">
      <label for="confidentialSwitch">Afișare "Confidențial"</label>
      <label class="switch">
        <input type="checkbox" id="confidentialSwitch" ${showConfidential ? 'checked' : ''}>
        <span class="slider round"></span>
      </label>
    </div>
    `
  );

  // Afișăm modalul Swal cu elementul creat
  Swal({
    title: 'Export PDF tabel',
    text: text,
    content: container,
    icon: 'warning',
    buttons: ['Anulează', 'Da, continui!'],
    dangerMode: true,
  }).then((willContinue) => {
    if (willContinue) {
      const headerSwitch = document.getElementById('headerSwitch');
      const confidentialSwitch = document.getElementById('confidentialSwitch'); 

      handleExportPDF(headerSwitch.checked, confidentialSwitch.checked);
    }
  });
};

const handleExportPDF = (showHeader1, showConfidential1) => { 
  const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false && col.field !== 'actions');


  if (visibleColumns.length === 0 ) {
    console.error('Nu există date disponibile pentru a genera PDF-ul.');
    return;
  }

  let pageFormat = 'a4';  // Format implicit

  if (visibleColumns.length > 5 && visibleColumns.length <= 8) {
    pageFormat = 'a3';
  } else if (visibleColumns.length > 8 && visibleColumns.length <= 12) {
    pageFormat = 'a2';
  } else if (visibleColumns.length > 12 && visibleColumns.length <= 15) {
    pageFormat = 'a1';
  } else if (visibleColumns.length > 15) {
    pageFormat = 'a0';
  }

  // Inițializăm documentul PDF
  const doc = new jsPDF({
    orientation: 'landscape',  // Orientare landscape
    format: pageFormat,  
                // Dimensiunea paginii
  });

  // Adăugăm fontul DejaVu Sans
  callAddFont(doc); // Adăugăm fontul personalizat
  doc.setFont('DejaVuSans'); // Setăm fontul pentru text

  // Adăugăm un antet, dacă este selectat
  let startY = 20;
  if (showHeader1) {
    doc.setFontSize(12);
    doc.setTextColor('#333');
    
    // Verificăm dacă `usercurentgrup` conține mai multe grupuri
    const grupuri = usercurentgrup.split(','); // separăm grupurile prin virgulă
    let grupuriText;

    if (grupuri.length > 1) {
      // Dacă avem mai multe grupuri, afișăm "pentru 2 unități" și grupurile respective
      grupuriText = `pentru ${grupuri.length} unități (${usercurentgrup})`;
    } else {
      // Dacă avem un singur grup, afișăm "pentru unitatea" și grupul respectiv
      grupuriText = `pentru unitatea ${usercurentgrup}`;
    }

    const dateFilterTypeDen = startDate ? 
    (dateFilterType === 'datacreare' ? 'data creare dosar' :
    dateFilterType === 'datatermen' ? 'data termen dosar' :
    dateFilterType === 'dataum' ? 'data actualizare dosar' : '') 
  : '';
  
    let mesaj;

     // Cazul în care nu trimiți `startDate`
     mesaj = `Documentul de export a fost generat la data ${formattedDate} la ora ${formattedTime2}, ${grupuriText}.`;
     doc.text(mesaj, 14, startY);
    if (!startDate) {
      doc.text(`Exportul a fost realizat pentru toată perioada (fără restricții referitoare la date calendaristice) din JuristPRO NEXT ${denAppVers}.`, 14, startY + 10);
    } else {
      // Cazul în care trimiți `startDate`, dar poate nu și `endDate`
      const dataSfarsit = endDate ? endDate : formattedDate;  // dacă `endDate` nu este trimis, folosește data curentă
      mesaj = `Perioada pentru care a fost realizat exportul este: ${dateFilterTypeDen} ${formattedStartDate2} - ${formattedEndDate2} din JuristPRO NEXT ${denAppVers}., ${grupuriText}.`;
      doc.text(mesaj, 14, startY + 10);
    }

  startY += 12;  // Mutăm mai jos pentru a face loc textului de confidențialitate, dacă există
  }

  // Adăugăm un mesaj confidențial, dacă este selectat
  if (showConfidential1) {
    doc.setFontSize(14);
    doc.setTextColor(255, 0, 0);  // Roșu pentru confidențial
    doc.text(`CONFIDENTIAL utilizator ${usercurentnume}`, 14, startY + 10);
    startY += 10;  // Mutăm mai jos pentru a face loc tabelului
  }

  // Creăm anteturile și rândurile tabelului
  const tableColumnHeaders = visibleColumns.map(col => col.headerName);
  const tableRows = dosareFinal.map((row) =>
  visibleColumns.map((col) => {
    if (col.field === 'user_groups') {
      return formatUserGroups(row[col.field]); // Aplica funcția de formatare
    }
    if (col.field === 'data') {
        return formateazaData(row[col.field]); // Aplicăm formatarea datei
      }
    return row[col.field] || ''; // Returnează valoarea originală pentru celelalte coloane
  })
);

  if (tableRows.length === 0 || tableColumnHeaders.length === 0) {
    console.error('Nu există date pentru a genera tabelul.');
    return;
  }

  // Creăm tabelul cu `word wrap` și paginare
  doc.autoTable({
    head: [tableColumnHeaders],
    body: tableRows,
    startY: startY + 10, // Începem tabelul după antet și confidențial
    theme: 'grid',
    styles: {
      font: 'DejaVuSans',
      fontSize: 10,
      cellPadding: 3,
      overflow: 'linebreak',  // Activăm `word wrap` pentru text lung
      cellWidth: 'auto',      // Permitem auto-ajustarea celulelor
    },
    tableWidth: 'auto',  // Lăsăm tabelul să se ajusteze automat
    didDrawPage: (data) => {
      // Adăugăm paginare
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      const pageStr = `Pagina ${data.pageNumber} din ${pageCount}`;
      doc.text(pageStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
    },
  });
  // Salvăm PDF-ul pe dispozitivul utilizatorului
  doc.save(`Export JuristPRO - PDF - ${formattedDate} - ${formattedTime}.pdf`);

};
const handlePrintComand = (showHeader, showConfidential) => { 
    setShowHeader(false);
    setShowConfidential(false);
    let text;

    text = `Au fost selectate ${selectedRows2.length} dosare pentru a fi imprimate din tabel`;

    // Creăm un container HTML pentru switch-uri
    const container = document.createElement('div');
    container.className = 'divswal'; // Adăugăm o clasă pentru stilizare

    // Inserăm HTML pentru switch-uri personalizate stilizate
    container.insertAdjacentHTML(
      'beforeend',
      `
      <div class="switch-container">
        <label for="headerSwitch">Afișare antet</label>
        <label class="switch">
          <input type="checkbox" id="headerSwitch" ${showHeader ? '' : ''}>
          <span class="slider round"></span>
        </label>
      </div>
      <div class="switch-container">
        <label for="confidentialSwitch">Afișare "Confidențial"</label>
        <label class="switch">
          <input type="checkbox" id="confidentialSwitch" ${showConfidential ? '' : ''}>
          <span class="slider round"></span>
        </label>
      </div>
      `
    );

    // Afișăm modalul Swal cu elementul creat
    Swal({
      title: 'Imprimare tabel',
      text: text,
      content: container,
      icon: 'warning',
      buttons: ['Anulează', 'Da, continui!'],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        const headerSwitch = document.getElementById('headerSwitch');
        const confidentialSwitch = document.getElementById('confidentialSwitch'); 
        handlePrint(headerSwitch.checked, confidentialSwitch.checked);
      }
    });
  };

  const handlePrint = (showHeader1, showConfidential1) => { 
    const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false);

    if (visibleColumns.length === 0 || dosareFinal.length === 0) {
      console.error('Nu există date disponibile pentru a genera PDF-ul.');
      return;
    }

    
    let pageFormat = 'a4';  // Format implicit

    if (visibleColumns.length > 5 && visibleColumns.length <= 8) {
      pageFormat = 'a3';
    } else if (visibleColumns.length > 8 && visibleColumns.length <= 12) {
      pageFormat = 'a2';
    } else if (visibleColumns.length > 12 && visibleColumns.length <= 15) {
      pageFormat = 'a1';
    } else if (visibleColumns.length > 15) {
      pageFormat = 'a0';
    }

    // Inițializăm documentul PDF
    const doc = new jsPDF({
      orientation: 'landscape',  // Orientare landscape
      format: pageFormat,  
                  // Dimensiunea paginii
    });


        // Adăugăm fontul DejaVu Sans
        callAddFont(doc); // Adăugăm fontul personalizat
        doc.setFont('DejaVuSans'); // Setăm fontul pentru text
    
        // Adăugăm un antet, dacă este selectat
        let startY = 20;
        if (showHeader1) {
          doc.setFontSize(12);
          doc.setTextColor('#333');
          
          // Verificăm dacă `usercurentgrup` conține mai multe grupuri
          const grupuri = usercurentgrup.split(','); // separăm grupurile prin virgulă
          let grupuriText;
    
          if (grupuri.length > 1) {
            // Dacă avem mai multe grupuri, afișăm "pentru 2 unități" și grupurile respective
            grupuriText = `pentru ${grupuri.length} unități (${usercurentgrup})`;
          } else {
            // Dacă avem un singur grup, afișăm "pentru unitatea" și grupul respectiv
            grupuriText = `pentru unitatea ${usercurentgrup}`;
          }
      
          const dateFilterTypeDen = startDate ? 
            (dateFilterType === 'datacreare' ? 'data creare dosar' :
            dateFilterType === 'datatermen' ? 'data termen dosar' :
            dateFilterType === 'dataum' ? 'data actualizare dosar' : '') 
            : '';
        
          let mesaj;
    
           // Cazul în care nu trimiți `startDate`
           mesaj = `Documentul de export a fost generat la data ${formattedDate} la ora ${formattedTime2}, ${grupuriText}.`;
           doc.text(mesaj, 14, startY);
          if (!startDate) {
            doc.text(`Exportul a fost realizat pentru toată perioada (fără restricții referitoare la date calendaristice) din JuristPRO NEXT ${denAppVers}.`, 14, startY + 10);
          } else {
            // Cazul în care trimiți `startDate`, dar poate nu și `endDate`
            const dataSfarsit = endDate ? endDate : formattedDate;  // dacă `endDate` nu este trimis, folosește data curentă
            mesaj = `Perioada pentru care a fost realizat exportul este: ${dateFilterTypeDen} ${formattedStartDate2} - ${formattedStartDate2} din JuristPRO NEXT ${denAppVers}., ${grupuriText}.`;
            doc.text(mesaj, 14, startY + 10);
          }
    
        startY += 12;  // Mutăm mai jos pentru a face loc textului de confidențialitate, dacă există
        }

    // Adăugăm un mesaj confidențial, dacă este selectat
    if (showConfidential1) {
      doc.setFontSize(14);
      doc.setTextColor(255, 0, 0);  // Roșu pentru confidențial
      doc.text(`CONFIDENTIAL utilizator ${usercurentnume}`, 14, startY + 10);
      startY += 10;  // Mutăm mai jos pentru a face loc tabelului
    }

    // Creăm anteturile și rândurile tabelului
    const tableColumnHeaders = visibleColumns.map(col => col.headerName);
    const tableRows = dosareFinal.map((row) =>
    visibleColumns.map((col) => {
      if (col.field === 'user_groups') {
        return formatUserGroups(row[col.field]); // Aplica funcția de formatare
      }
      return row[col.field] || ''; // Returnează valoarea originală pentru celelalte coloane
    })
  );
  
    if (tableRows.length === 0 || tableColumnHeaders.length === 0) {
      console.error('Nu există date pentru a genera tabelul.');
      return;
    }

    // Creăm tabelul cu `word wrap` și paginare
    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      startY: startY + 10, // Începem tabelul după antet și confidențial
      theme: 'grid',
      styles: {
        font: 'DejaVuSans',
        fontSize: 10,
        cellPadding: 3,
        overflow: 'linebreak',  // Activăm `word wrap` pentru text lung
        cellWidth: 'auto',      // Permitem auto-ajustarea celulelor
      },
      tableWidth: 'auto',  // Lăsăm tabelul să se ajusteze automat
      didDrawPage: (data) => {
        // Adăugăm paginare
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        const pageStr = `Pagina ${data.pageNumber} din ${pageCount}`;
        doc.text(pageStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
      },
    });
    
    // Salvăm PDF-ul pe dispozitivul utilizatorului
    doc.save(`Export JuristPRO - PDF - ${formattedDate} - ${formattedTime}.pdf`);

    // Creăm un URL temporar pentru a deschide PDF-ul într-o fereastră nouă
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    window.open(url); 

  };
  
const CustomGridToolbarContainer = ({}) => {

    return (
<GridToolbarContainer>
        <FlexContainer>
        <Box sx={{ display: 'flex', alignItems: 'left', mr: 1  }}>
        <GridToolbarColumnsButton /> 
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        
        <GridToolbarExportContainer >
            <MenuItem onClick={handlePrintComand}>
            Imprimare tabel
            </MenuItem>

            <MenuItem onClick={handleExportPDFComand}>
            Export PDF tabel
            </MenuItem>

            
            <ExportInExcel
                csvData={prepareDataForExport2(
                    dosareFiltrate,
                    columns,
                    columnVisibilityModel
                )} 
                fileName={fileNameExcel}
                showHeader={showHeader}
                showConfidential={showConfidential}
                afisamSwal={true} 
                startDate={formattedStartDate2}
                dateFilterType={dateFilterType} 
                endDate={formattedEndDate2}
                />

             
            <MenuItem onClick={handleExportXML}>
                 În format XML
            </MenuItem>

        </GridToolbarExportContainer>
                

        </Box>
        </FlexContainer>
      </GridToolbarContainer>
    );
  }; 

 

     // Funcție pentru obținerea id-ului curent al utilizatorului din localStorage, sessionStorage sau cookie
     const getCurrentUserId = () => {
        let userId = localStorage.getItem('iduser') || sessionStorage.getItem('iduser');
        if (!userId) {
          const cookies = document.cookie.split(';');
          for (let cookie of cookies) {
            const [name, value] = cookie.trim().split('=');
            if (name === '_iduserCjpc') {
              userId = value;
              break;
            }
          }
        }
        return userId;
      };
     // Fetch utilizatori din API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}users?users_from_all_common_groups=1`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const { data } = await response.json();
        setUsers(data.users);

        // Setează utilizatorul curent o singură dată
        if (!initialUserSet) {
          const currentUserId = getCurrentUserId();
          const currentUser = data.users.find(user => user.id.toString() === currentUserId);
          if (currentUser) {
            setSelectedUser(currentUser);
            setInitialUserSet(true); // Marchează faptul că utilizatorul curent a fost setat
          }
        }
      } catch (error) {
        console.error("Eroare la preluarea utilizatorilor", error);
      }
    };

    fetchUsers();
  }, [token, initialUserSet]);

    
  const handleUserChange = (event, newUser) => {
    if (!newUser) return;

    setSelectedUser(newUser); // Setăm utilizatorul selectat
    fetchActivitati(newUser.id); // Încărcăm activitățile pentru noul utilizator
  };

    
      return (
      <div className=" "> 
        <Typography style={{fontWeight: 'bold', fontSize:'1.3em'}} className='mb-2'>
            {areDrept(AdaugareUtilizatoriDrept) ? (
                    <Autocomplete className='mt-3 mb-2'
                        options={users}
                        getOptionLabel={(option) => option.fullname || option.name}
                        value={selectedUser} // Valoarea curentă a utilizatorului selectat
                        onChange={handleUserChange}
                        renderInput={(params) => <TextField {...params} label="Selectează un utilizator" variant="outlined" />}
                    />
            ) : null }
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} className='mb-2'>
            <Typography style={{fontWeight: '500', fontSize:'1.3em'}}>
                Timp total activități: {oreTotale} ore și {minuteTotale} minute
            </Typography>
            {areDrept(DreptDeModificareDosare) ? ( 
           <Box>
            <Button disabled={!areDrept(DreptDeModificareDosare)} color="primary" size="small" variant="contained" style={{marginRight:'20px'}} startIcon={<AddIcon />} onClick={deschideModalAdaugare}>
                Adăugare activitate utilizator
            </Button>
            <Button disabled={!areDrept(DreptDeModificareDosare)} color="warning" size="small" variant="contained" startIcon={<AddIcon />} onClick={deschideModalAdaugareDosar}>
                Adăugare activitate la dosar
            </Button>
            </Box>
            ) : null } 
        </div>

      <Dialog open={modalDeschis} onClose={() => setModalDeschis(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className='titluModal mb-3'>Adăugare / Editare activitate</DialogTitle>
        <DialogContent>
        {!activitateUser && (
            <Autocomplete
                options={dosare}
                getOptionLabel={(option) => option.nume}
                value={dosarSelectat}
                onChange={(event, newValue) => setDosarSelectat(newValue)}
                renderInput={(params) => <TextField {...params} label="Dosar" margin="dense" />}
            />
            )}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data"
              value={dateFormular.data}
              onChange={(newValue) => {
                setDateFormular((prev) => ({ ...prev, data: newValue }));
              }}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="dd/MM/yyyy" 
              format="dd/MM/yyyy" 
              mask="__/__/____"
              className='maxWidth mt-3'
            />
          </LocalizationProvider>
          <TextField margin="dense" label="Descriere" type="text" fullWidth multiline value={dateFormular.text} onChange={e => setDateFormular(prev => ({ ...prev, text: e.target.value }))} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              select
              label="Timp (ore)"
              margin="dense"
              value={dateFormular.timp}
              onChange={e => setDateFormular(prev => ({ ...prev, timp: e.target.value }))}
              style={{ flex: 1, marginRight: 10 }}
              SelectProps={{
                MenuProps: {
                  style: { maxHeight: 300 }, 
                },
              }}
            >
              {oreOptions}
            </TextField>

              <TextField
                select
                label="Timp (minute)"
                margin="dense"
                value={dateFormular.minute}
                onChange={handleMinuteChange}  
                style={{ flex: 1 }}
                SelectProps={{
                  MenuProps: {
                    style: { maxHeight: 300 }, 
                  },
                }}
              >
                {minuteOptions}
              </TextField>


          </div>


        </DialogContent>
        <DialogActions>
            <Button onClick={() => setModalDeschis(false)} color="error">Anulare</Button>
            <Button onClick={handleSalvare} color="success" disabled={!isFormularCompletValid}>{editareId ? "Actualizare" : "Adăugare"}</Button>
        </DialogActions>
      </Dialog>
 
       
            {incarca ? (
                <CircularProgress />
            ) : activitati.length === 0 ? (
                <Alert severity="info" className='mt-3'>Nu există activități înregistrate pentru acest dosar.</Alert>
            ) : (
                <DataGridPro
                rows={dosareFiltrate}
                columns={columns}
                autoHeight
                pagination
                loading={isLoading} 
                pageSize={20}
                apiRef={apiRef2}
                onStateChange={handleStateChange}
                onFilterModelChange={handleFilterModelChange}
                onSortModelChange={handleSortModelChange} 
                filterModel={filterModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                columnVisibilityModel={columnVisibilityModel}
                rowsPerPageOptions={[10, 20, 50, 100]}
                checkboxSelectionVisibleOnly={true}
                disableRowSelectionOnClick
                disableSelectionOnClick={true}
                paginationMode="client"  
                initialState={{
                    pagination: { paginationModel: { pageSize: 15 } }, 
                }}
                 
                density='compact'
                components={{ 
                    
                    headerFilterMenu: null, 
                    Toolbar: () => (
                    <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                      </div>
                        {isLoading ? (
                        <div style={{ width: '100%', marginBottom: 8 }}>
                            
                            <CustomGridToolbarContainer />
                        <LinearProgress /> 
                        </div>
                        ) : (
                        <div>
                        <CustomGridToolbarContainer />
                        </div>
                        
                        )}
                    </>
                    ),
                }}
                componentsProps={{
                    toolbar: {
                    csvOptions: {
                        fileName: 'nume-fisier',
                        delimiter: ',',
                        utf8WithBom: true,
                    },
                    },
                }}
                localeText={{ 
                    ...roRO.components.MuiDataGrid.defaultProps.localeText, 
                    headerFilterOperatorContains: 'Filtrare rapidă',
                    filterPanelRemoveAll: 'Resetare filtre',
                    toolbarExport: 'Export',
                    toolbarExportLabel: 'Export',
                    toolbarExportCSV: 'În format CSV',
                    toolbarExportPrint: 'Imprimare',
                    toolbarExportExcel: 'În format Excel',
                }}
              />
            )} 
 

        </div>
    );
}

export default Activitati;
