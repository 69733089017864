import React, { useState, useEffect } from 'react';
import { DataGridPro, GridToolbar, roRO } from '@mui/x-data-grid-pro';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { Alert, Button, IconButton, LinearProgress, Tooltip, Badge  } from '@mui/material';
import Swal from 'sweetalert'; 
import verificareToken from './VerificareToken'; 
import { LicenseInfo } from '@mui/x-license-pro'; 
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

LicenseInfo.setLicenseKey('ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


function RecuperareDosareSterse() {

    const [selectedRowsdosareSterse, setSelectedRowsdosareSterse] = useState([]);

    const [selectedRows2dosareSterse, setSelectedRows2dosareSterse] = React.useState([]);

    const [dosareSterse, setDosareSterse] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
      const { token } = verificareToken();
    
    const [selectedDosare, setSelectedDosare] = useState([]);

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}dosareSterse`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setDosareSterse(data.data.dosare);
            } catch (error) {
                console.error('Eroare la încărcarea dosarelor șterse:', error);
                Swal('Atenționare', 'A apărut o eroare la încărcarea dosarelor șterse', 'warning');
            } finally {
                setIsLoading(false);
            }
        };
    useEffect(() => {
        fetchData();
    }, []);

    const handleRecuperare = (id) => {
        Swal({
          title: 'Recuperați acest dosar?',
          text: 'Atenție, urmează să recuperați un dosar șters.',
          icon: 'warning',
          buttons: ['Anulează', 'Da, recuperează!'],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            handleRecuperareOK(id);
          }
        });
      };
  
      
      const handleRecuperareOK = async (ids) => {
        const idParams = ids.map(id => `id_dosar[]=${id}`).join('&');

        try {
            await fetch(`${process.env.REACT_APP_API_URL}restore?${idParams}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            Swal('Succes', 'Dosarele au fost recuperate cu succes', 'success');
            fetchData();
        } catch (error) {
            console.error('Eroare la recuperarea dosarelor:', error);
            Swal('Atenționare', 'A apărut o eroare la recuperarea dosarelor', 'warning');
        }
    };

    const handleSelectionModelChange = (newSelectionModel, columnVisibility) => {
        setSelectedRowsdosareSterse(newSelectionModel);
      
        const selectedIDsdosareSterse = new Set(newSelectionModel.map(String));
        const selectedRowsDatadosareSterse = selectedDosare.filter((row) =>
          selectedIDsdosareSterse.has(String(row.id))
        ).map(row => {
          const visibleData = {};
          for (const key in row) {
            if (columnVisibility[key] !== false) { // daca coloana nu e setata invizibila
              visibleData[key] = row[key];
            }
          }
          return visibleData;
        });
        
        setSelectedRows2dosareSterse(selectedRowsDatadosareSterse);
      
 
      };
      

      const [isDialogOpen, setIsDialogOpen] = useState(false);
      const [password, setPassword] = useState('');
      const [selectedId, setSelectedId] = useState(null);
    
      const handleOpenDialog = (id) => {
        setIsDialogOpen(true);
        setSelectedId(id || null);
      };
      
    
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setPassword('');
        setSelectedId(null);  
      };
      
    
      const handleConfirmDelete = async () => {
        let ids = [];
        let deleteMessage = '';
        
        if (selectedRowsdosareSterse.length > 0) {
            ids = selectedRowsdosareSterse;
            deleteMessage = `Cele ${selectedRowsdosareSterse.length} dosare selectate au fost șterse definitiv!`;
        } else if (selectedId) {
            ids = [selectedId];
            deleteMessage = 'Dosarul selectat a fost șters definitiv!';
        }
        
        const requestBody = {
            password: password,
            ids: ids
        };
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}stergeDefinitiv`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody)
            });
            const data = await response.json();
            if (response.ok) {
                Swal('Ștergere completă', deleteMessage, 'success');
                fetchData();  
            } else {
                // Verificăm dacă există un mesaj de eroare detaliat în obiectul de eroare
                throw new Error(data.message || 'A apărut o eroare la ștergere');
            }
        } catch (error) {
            // Verificăm dacă eroarea conține un mesaj specific
            const errorMessage = error.message || 'A apărut o eroare necunoscută';
            Swal('Ștergere completă', deleteMessage, 'success');
        } finally {
            handleCloseDialog();
        }
    };
    
    
    const handleRecuperareMultiple = () => {
        if (selectedRowsdosareSterse.length === 0) {
            Swal('Atenționare', 'Nu ați selectat niciun dosar pentru recuperare.', 'warning');
            return;
        }
    
        const titleText = selectedRowsdosareSterse.length === 1
            ? 'Recuperați dosarul selectat?'
            : 'Recuperați dosarele selectate?';
        
        const bodyText = selectedRowsdosareSterse.length === 1
            ? 'Sunteți sigur că doriți să recuperați dosarul selectat?'
            : `Sunteți sigur că doriți să recuperați cele ${selectedRowsdosareSterse.length} dosare selectate?`;
    
        Swal({
            title: titleText,
            text: bodyText,
            icon: 'warning',
            buttons: ['Anulează', 'Da, recuperează!'],
            dangerMode: true,
        }).then((willRecover) => {
            if (willRecover) {
                handleRecuperareOK(selectedRowsdosareSterse);
            }
        });
    };
  
      
    const columns = [
        { field: 'numardosarfinal', headerName: 'Număr dosar', width: 150 },
        { field: 'client', headerName: 'Client', width: 200 },
        { field: 'calitateclientdosar', headerName: 'Calitate client', width: 140 },
        { field: 'parteadversa', headerName: 'Parte adversă', width: 200 },
        { field: 'denumirestadiu', headerName: 'Stadiu dosar', width: 100 },
        { field: 'deninstanta', headerName: 'Instanța', width: 200 },
        {
          field: 'actions', 
          headerAlign: 'center',
          headerName: 'Acțiuni', 
          width: 150,
          sortable: false,
          pinned: 'right',
          renderHeader: (params) => (
            <>
            <Tooltip title={`Recuperare dosar${selectedRowsdosareSterse.length > 1 ? 'e' : ''} din listă`}>
                <span>  
                  <IconButton 
                    onClick={() => handleRecuperareMultiple(selectedRowsdosareSterse)}
                    disabled={selectedRowsdosareSterse.length === 0}
                  >
                    <RestoreFromTrashIcon  />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={`Șterge dosar${selectedRowsdosareSterse.length > 1 ? 'e' : ''} din listă`}>
              <span>  
                <IconButton 
                  onClick={() => handleOpenDialog(selectedRowsdosareSterse)}
                  disabled={selectedRowsdosareSterse.length === 0}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
          ),
          renderCell: (params) => (
            <>
              <Tooltip title="Recuperare dosar">
                <IconButton onClick={() => handleRecuperare(params.row.id)}>
                  <RestoreFromTrashIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ștergere definitivă">
                <IconButton onClick={() => handleOpenDialog(params.row.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )
        },
    ];

    return (
        <div style={{ height: 350, width: '100%' }}>
            {isLoading ? <LinearProgress /> : null}
            <DataGridPro
                rows={dosareSterse}
                columns={columns} 
                loading={isLoading}
                pageSize={20}
                checkboxSelection  
                checkboxSelectionVisibleOnly={false}
                disableSelectionOnClick={true}
                onSelectionModelChange={(ids) => {
                    const selectedIDsdosareSterse = new Set(ids);
                    const selectedRowsdosareSterse = dosareSterse.rows.filter((row) =>
                      selectedIDsdosareSterse.has(row.id),
                    ); 
                    setSelectedRowsdosareSterse(selectedRowsdosareSterse); 
                  }}
                  onRowSelectionModelChange  ={handleSelectionModelChange} 
                rowsPerPageOptions={[10, 20, 50, 100]}
                pagination
                paginationMode="client"  
                localeText={{ 
                  ...roRO.components.MuiDataGrid.defaultProps.localeText, 
                  headerFilterOperatorContains: 'Filtrare rapidă',
                  filterPanelRemoveAll: 'Resetare filtre',
                  toolbarExport: 'Export',
                  toolbarExportLabel: 'Export',
                  toolbarExportCSV: 'În format CSV',
                  toolbarExportPrint: 'Imprimare',
                  toolbarExportExcel: 'În format Excel',
                }} 
                density="compact"  
                
                initialState={{
                    pinnedColumns: {
                      right: ['actions'],
                    },
                  }}
            />

    <Dialog open={isDialogOpen} > 
        <DialogTitle className='titluModal mb-3'>Confirmare ștergere dosar</DialogTitle>
        <DialogContent>
          <h4>Sunteți sigur că doriți să ștergeți definitiv dosarul?</h4>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Parolă aplicație"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Alert severity='warning' className='mt-2'>Această acțiune este ireversibilă!</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Anulează</Button>
          <Button onClick={handleConfirmDelete} color='error' disabled={!password}>Șterge Definitiv</Button>
        </DialogActions>
      </Dialog>


            {/*<pre style={{ fontSize: 10 }}>
        {JSON.stringify(selectedRowsdosareSterse, null, 4)}
                </pre>*/}
        </div>
    );
}

export default RecuperareDosareSterse;
